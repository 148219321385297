// This is where you dynamically change information for an identical site with a different domain.
// Don't forget to import any images you may need

import dynamicSiteConfig from '../../../../src/utils/dynamic/dynamicSiteConfig';

/**
 *
 * @param {*} config - config object. Use this to alter any config options you need to
 * @param {*} setDocumentTitle - function. Needed to implicitly change the document title
 * @param {*} query - params object.
 */

const customConfig = config => {
  /**
   * updates main config from dynamicSites config
   * @param  {object} config
   */
  dynamicSiteConfig(config);

  const url = document.URL;
  const altUrl = 'seniorsaversclub.org';

  if (url.includes(altUrl)) {
    const header = document.getElementById('header');
    if (header) {
      header.classList.add('senior');
    }
    // change any config information you need here
    // config.organicLink = '';
    // config.headline = 'New headline';
    // config.offerId = 0000;
    // setDocumentTitle('New Title');
    // document.getElementById('header-image').src = newLogo;
  }
};

export default customConfig;
